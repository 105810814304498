<template>
  <div>
    <div style="padding: 20px">
      <van-icon name="scan" size="60" @click="scanAction" />
    </div>

    <audio src="../assets/readok.mp3" id="eventAudio"></audio>
  </div>
</template>

<script>
import { Icon, Dialog, Toast } from "vant";
import wx from "weixin-js-sdk";
var _ = require("lodash");
// import Vconsole from "vconsole";
// new Vconsole();

export default {
  components: {
    vanIcon: Icon,
  },
  data() {
    return {};
  },
  mounted() {
    this.http.post(
      this.ports.user.wxSignature,
      {
        tokenUnuse: true,
        code: process.env.VUE_APP_WX_CODE,
        url: window.location.href.split("#")[0],
      },
      (res) => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          jsApiList: ["checkJsApi", "scanQRCode"], // 必填，需要使用的JS接口列表
        });
        wx.error(function (res) {
          // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
          console.log("配置验证失败: " + res.errMsg);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  },
  methods: {
    scanAction() {
      let self = this;
      wx.ready(function () {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            console.log("code:" + result);

            // 提示音
            var ua = navigator.userAgent.toLowerCase();
            if (/android/.test(ua)) {
              let buttonAudio = document.getElementById("eventAudio");
              buttonAudio.play();
            }

            self.handleQRCode(result);
          },
        });
      });
    },
    handleQRCode(code) {
      if (_.startsWith(code, "pgcCoupon")) {
        let id = _.replace(code, "pgcCoupon", "");
        this.http.put(
          this.ports.coupon.couponScan(id),
          {
            tokenUnuse: true,
          },
          (res) => {
            console.log(res);
            Dialog.alert({
              message: "核销成功",
            }).then(() => {
              // on close
            });
          },
          (err) => {
            console.log(err);
            Toast.fail(err.message);
          }
        );
      } else {
        Toast.fail("错误的二维码");
      }
    },
  },
};
</script>
<template>
  <div style="background: #f8f8f9">
    <div
      style="padding: 8px; color: #17233d"
      v-for="(item, i) in data.shop_locations"
      :key="i"
    >
      {{
        item.market +
        " " +
        item.building +
        " " +
        item.floor +
        "F" +
        " " +
        item.road +
        " " +
        item.number +
        "号"
      }}
    </div>
    <van-row>
      <van-col span="24">
        <div style="padding: 8px; float: left; color: #17233d; font-size: 12px">
          经营户:
        </div>
      </van-col>
      <van-col
        span="24"
        style="padding: 8px; text-align: left; color: #515a6e; font-size: 12px"
        v-for="(item, i) in data.business_users"
        :key="'user' + i"
      >
        {{ item.name + " " + item.mobile }}
      </van-col>
      <van-col span="24" v-if="data.register_pictures.length > 0">
        <div style="padding: 8px; float: left; color: #17233d; font-size: 12px">
          品牌注册书:
        </div>
      </van-col>
      <van-col span="24" v-if="data.register_pictures.length > 0"
        ><van-image
          v-for="(img, j) in data.register_pictures"
          :key="'register_pictures' + j"
          :src="setImg(img)"
          lazy-load
          @click="seeImg(img)"
        />
      </van-col>
      <van-col span="24" v-if="data.grant_pictures.length > 0">
        <div style="padding: 8px; float: left; color: #17233d; font-size: 12px">
          品牌授权书:
        </div>
      </van-col>
      <van-col span="24" v-if="data.grant_pictures.length > 0"
        ><van-image
          v-for="(img, j) in data.grant_pictures"
          :key="'grant_pictures' + j"
          :src="setImg(img)"
          lazy-load
          @click="seeImg(img)"
        />
      </van-col>
      <van-col span="24" v-if="data.upload_time">
        <div style="padding: 8px; float: left; color: #515a6e; font-size: 12px">
          上传日期:{{ data.upload_time }}
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { Image, Col, Row, Lazyload } from "vant";
import Vue from "vue";

Vue.use(Lazyload);

export default {
  components: {
    vanImage: Image,
    vanRow: Row,
    vanCol: Col,
  },
  data() {
    return {
      data: {
        business_users: [],
        shop_locations: [],
        register_pictures: [],
        grant_pictures: [],
        upload_time: null,
      },
    };
  },
  mounted() {
    this.http.get(
      this.ports.store.grant(this.$route.query.shopId),
      {
        tokenUnuse: true,
      },
      (res) => {
        console.log(res);
        this.data = res;
      },
      (err) => {
        console.log(err);
      }
    );
  },
  methods: {
    setImg(id) {
      return "/img/" + id;
    },
    seeImg(img) {
      console.log(img);
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke("imagePreview", {
        urls: [process.env.VUE_APP_IMG_URL + "/" + img],
        current: process.env.VUE_APP_IMG_URL + "/" + img,
      });
    },
  },
};
</script>
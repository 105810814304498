<template>
  <div style="padding: 10px">
    <van-form @submit="onSubmit" :label-width="60">
      <van-field
        readonly
        clickable
        name="carNumber"
        :value="carNumber"
        label="车牌号"
        placeholder="点击输入车牌号"
        @click="showInputCar = true"
        style="margin-top: 60px; margin-bottom: 60px"
      />

      <div style="margin: 20px">
        <van-button
          :loading="loading"
          round
          block
          type="info"
          native-type="onSubmit"
          :disabled="!carNumber"
        >
          确认
        </van-button>
      </div>
    </van-form>

    <van-popup v-model="showInputCar" round position="bottom">
      <vue-vehicle-plate
        v-if="showInputCar"
        @confirm="submit"
      ></vue-vehicle-plate>
    </van-popup>
  </div>
</template>

<script>
import { Form, Field, Popup, Button, Toast, Dialog } from "vant";

import VueVehiclePlate from "vue-vehicle-plate";
import Vue from "vue";
Vue.use(VueVehiclePlate);

export default {
  components: {
    vanForm: Form,
    vanField: Field,
    vanPopup: Popup,
    vanButton: Button,
  },
  data() {
    return {
      showInputCar: false,
      carNumber: null,
      loading: false,
    };
  },
  mounted() {
    console.log(this.$route.query.code);
  },
  methods: {
    onSubmit() {
      Dialog.confirm({
        title: "车牌号：" + this.carNumber,
        message: "确认将优惠券领取到这辆车吗？",
      })
        .then(() => {
          // on confirm
          this.loading = true;
          this.http.post(
            this.ports.park.couponBind(this.$route.query.domain),
            {
              qr_code: this.$route.query.code,
              plate: this.carNumber,
            },
            (res) => {
              console.log(res);
              this.loading = false;
              Dialog.alert({
                message: "领取成功",
              }).then(() => {
                // on close
                // eslint-disable-next-line no-undef
                WeixinJSBridge.call("closeWindow");
              });
            },
            (err) => {
              console.log(err);
              this.loading = false;
              Toast.fail(err.message);
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    submit(value) {
      console.log(value);
      this.carNumber = value.plateNum;
      this.showInputCar = false;
    },
  },
};
</script>